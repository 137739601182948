import moment from "moment";

export class Fixtures {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Fixtures";
    this.ff_scene = "Fixtures";
    this.mini_scene = "Fixtures Mini";
    this.data = [];
    this.page = 1;
  }

  getScore(game) {
    let try_points = game?.competition?.competition_type === "union" ? 5 : 4;
    let conversion_points =
      game?.competition?.competition_type === "union" ? 2 : 2;
    let penalty_kick_points =
      game?.competition?.competition_type === "union" ? 3 : 2;
    let penalty_try_points =
      game?.competition?.competition_type === "union" ? 7 : 4;
    let drop_goal_points =
      game?.competition?.competition_type === "union" ? 3 : 1;

    let home_score =
      (game?.home_scorers?.filter((g) => g.type === "try").length *
        try_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "conversion").length *
        conversion_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "penalty_kick").length *
        penalty_kick_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "penalty_try").length *
        penalty_try_points || 0) +
      (game?.home_scorers?.filter((g) => g.type === "drop_goal").length *
        drop_goal_points || 0);
    let home_tries =
      game?.home_scorers?.filter((g) => g.type === "try").length || 0;
    let home_conv =
      game?.home_scorers?.filter((g) => g.type === "conversion").length || 0;
    let home_pen_kick =
      game?.home_scorers?.filter((g) => g.type === "penalty_kick").length || 0;
    let home_pen_try =
      game?.home_scorers?.filter((g) => g.type === "penalty_try").length || 0;
    let home_drop_goal =
      game?.home_scorers?.filter((g) => g.type === "drop_goal").length || 0;
    let away_score =
      (game?.away_scorers?.filter((g) => g.type === "try").length *
        try_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "conversion").length *
        conversion_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "penalty_kick").length *
        penalty_kick_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "penalty_try").length *
        penalty_try_points || 0) +
      (game?.away_scorers?.filter((g) => g.type === "drop_goal").length *
        drop_goal_points || 0);
    let away_tries =
      game?.away_scorers?.filter((g) => g.type === "try").length || 0;
    let away_conv =
      game?.away_scorers?.filter((g) => g.type === "conversion").length || 0;
    let away_pen_kick =
      game?.away_scorers?.filter((g) => g.type === "penalty_kick").length || 0;
    let away_pen_try =
      game?.away_scorers?.filter((g) => g.type === "penalty_try").length || 0;
    let away_drop_goal =
      game?.away_scorers?.filter((g) => g.type === "drop_goal").length || 0;
    return {
      home_score,
      away_score,
      home_tries,
      home_conv,
      home_pen_kick,
      home_pen_try,
      home_drop_goal,
      away_tries,
      away_conv,
      away_pen_kick,
      away_pen_try,
      away_drop_goal,
    };
  }

  preview({ options }) {
    if (!options.page) {
      this.page = 1;
    }
    this.options = options;
    if (this.options.mini) {
      this.scene = this.mini_scene;
    } else {
      this.scene = this.ff_scene;
    }
    this.onAir = false;

    this.data = [];

    this.data.push({
      name: "Page",
      value: { text: this.page },
    });
    this.data.push({
      name: "Competition",
      value: { text: options.game?.competition?.name || "" },
    });
    this.data.push({
      name: "Competition Colour",
      value: { colour: options.game?.competition?.colour || "" },
    });
    this.data.push({
      name: "Competition Badge",
      value: { image: "${user}" + options.game?.competition?.badge || "" },
    });
    this.data.push({
      name: "Round",
      value: { text: options.game?.round || "" },
    });
    options.fixtures = JSON.parse(JSON.stringify(options?.fixtures))?.sort(
      (a, b) => {
        return moment(a?.time, "HH:mmZ") - moment(b?.time, "HH:mmZ");
      }
    );
    options.fixtures = options?.fixtures?.sort((a, b) => {
      let a_date = moment(a.date + " " + a.time);
      let b_date = moment(b.date + " " + b.time);
      // Else go to the 2nd item
      if (a_date < b_date) {
        return -1;
      } else if (a_date > b_date) {
        return 1;
      } else {
        // nothing to split them
        if (a?.home_team?.name < b?.home_team?.name) {
          return -1;
        }
        if (a?.home_team?.name > b?.home_team?.name) {
          return 1;
        }
        return 0;
      }
    });
    new Array(36)?.fill(1)?.map((_, index) => {
      let game = options?.fixtures?.[index];

      let row = index + 1;

      this.data.push({
        name: "Game " + row + " Home Team Short Name",
        value: { text: game?.home_team?.short_name || "" },
      });
      this.data.push({
        name: "Game " + row + " Home Team Name",
        value: { text: game?.home_team?.name || "" },
      });
      this.data.push({
        name: "Game " + row + " Home Team Badge",
        value: { image: "${user}" + game?.home_team?.variables?.badge },
      });

      this.data.push({
        name: "Game " + row + " Home Score",
        value: { text: game?.oval_scores?.home_team?.currentScore || "0" },
      });

      this.data.push({
        name: "Game " + row + " Home Tries",
        value: {
          text: game?.home_team_stats?.scoring?.tryScored,
        },
      });

      this.data.push({
        name: "Game " + row + " Away Team Name",
        value: { text: game?.away_team?.name || "" },
      });

      this.data.push({
        name: "Game " + row + " Away Team Short Name",
        value: { text: game?.away_team?.short_name || "" },
      });

      this.data.push({
        name: "Game " + row + " Away Team Badge",
        value: { image: "${user}" + game?.away_team?.variables?.badge },
      });

      this.data.push({
        name: "Game " + row + " Away Score",
        value: { text: game?.oval_scores?.away_team?.currentScore || "0" },
      });

      this.data.push({
        name: "Game " + row + " Away Tries",
        value: {
          text: game?.away_team_stats?.scoring?.tryScored,
        },
      });

      this.data.push({
        name: "Game " + row + " Kick-Off",
        value: {
          text: moment(game?.time, "HH:mmZ")?.format("HH:mm") || "",
        },
      });

      this.data.push({
        name: "Game " + row + " Date",
        value: { text: game?.date || "" },
      });

      let period = [...(game?.live_data?.periods || [])].sort(
        (a, b) => b.number - a.number
      )[0];

      this.data.push({
        name: "Game " + row + " Status",
        value: {
          text: game?.oval_status,
        },
      });
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }
  animate({ options }) {
    this.page++;
    this.options.page = this.page;
    this.onAir = true;
    this.sendAnimate(this.scene, this.data);
    this.preview({ options: this.options });
  }
  animateOff({ options }) {
    this.page = 1;
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
