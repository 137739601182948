export class Standings {
  constructor({ animate, preview, update, animateOff }) {
    this.onAir = false;
    this.sendAnimate = animate;
    this.sendAnimateOff = animateOff;
    this.sendPreview = preview;
    this.sendUpdate = update;
    this.name = "Standings";
    this.mini_scene = "Standings Mini";
    this.ff_scene = "Standings";
    this.data = [];
  }

  preview({ options }) {
    this.onAir = false;
    this.options = options;
    this.data = [];
    if (this.options.mini) {
      this.scene = this.mini_scene;
    } else {
      this.scene = this.ff_scene;
    }
    this.data.push({
      name: "Title",
      value: { text: options?.title || "" },
    });
    new Array(20).fill(1)?.map((_, index) => {
      let team = options.data?.[index];
      if (team) {
        let t = options.teams?.find(
          (t) => t.oval_ID === team.team?.id?.toString()
        );

        this.data.push({
          name: "Highlight " + (index + 1),
          value: {
            alpha:
              options.highlight &&
              (team.teamID === options?.game?.home_team?.rugby_league_ID ||
                team.teamID === options?.game?.away_team?.rugby_league_ID)
                ? 1
                : 0,
          },
        });
        this.data.push({
          name: "Position " + (index + 1),
          value: { text: team.position || "" },
        });
        this.data.push({
          name: "Team Code " + (index + 1),
          value: { text: t.code || "" },
        });
        this.data.push({
          name: "Team Name " + (index + 1),
          value: { text: t.name || "" },
        });
        this.data.push({
          name: "Team Short Name " + (index + 1),
          value: {
            text: t?.short_name,
          },
        });
        this.data.push({
          name: "Team Badge " + (index + 1),
          value: { image: "${user}" + t?.variables?.badge || "" },
        });
        this.data.push({
          name: "Played " + (index + 1),
          value: { text: team.P || "0" },
        });
        this.data.push({
          name: "Won " + (index + 1),
          value: { text: team.W || "0" },
        });
        this.data.push({
          name: "Lost " + (index + 1),
          value: { text: team.L || "0" },
        });
        this.data.push({
          name: "Drawn " + (index + 1),
          value: { text: team.D || "0" },
        });
        this.data.push({
          name: "Diff " + (index + 1),
          value: { text: team.DIFF || "0" },
        });
        this.data.push({
          name: "Points " + (index + 1),
          value: { text: team.PTS || "0" },
        });
      } else {
        this.data.push({
          name: "Position " + (index + 1),
          value: { text: "" },
        });
        this.data.push({
          name: "Team Name " + (index + 1),
          value: { text: "" },
        });
        this.data.push({
          name: "Team Badge " + (index + 1),
          value: { image: "" },
        });
        this.data.push({
          name: "Played " + (index + 1),
          value: { text: "" },
        });
        this.data.push({
          name: "Won " + (index + 1),
          value: { text: "" },
        });
        this.data.push({
          name: "Lost " + (index + 1),
          value: { text: "" },
        });
        this.data.push({
          name: "Drawn " + (index + 1),
          value: { text: "" },
        });
        this.data.push({
          name: "Diff " + (index + 1),
          value: { text: "" },
        });
        this.data.push({
          name: "Points " + (index + 1),
          value: { text: "" },
        });
      }
    });

    this.sendPreview(this.scene, 500, this.data, "IN");
  }

  animate({ options }) {
    this.onAir = true;
    this.sendAnimate(this.scene, this.data);
  }
  animateOff({ options }) {
    clearTimeout(this.to);
    this.onAir = false;
    this.sendAnimateOff(this.scene, []);
  }
}
